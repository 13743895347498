<template>
  <div class="event-participants">
    <event-participants-nav-bar />
    <router-view></router-view>
  </div>
</template>

<script>
import EventParticipantsNavBar from "@/components/template/event/event-participants/EventParticipantsNavBar.vue";
import {mapGetters} from "vuex";

export default {
  name: "EventParticipants",
  components: {EventParticipantsNavBar},
  data () {
    return {
      search: '',
      visibleFilter: false,
      visibleSort: false,
      sortData: [
        { text: this.$t('by-region'), value: 'region' },
        { text: this.$t('by-role'), value: '' }
      ],
      getRoleOptions: [
        { name: this.$t('inspector'), value: 'Inspector' },
        { name: this.$t('business'), value: 'Company' },
        { name: this.$t('public-authority'), value: 'Government' },
        { name: this.$t('eco-lawyer'), value: 'Lawyer' },
      ],
      sortBy: '',
      searchObj: {
        region: '',
        role: ''
      }
    }
  },
  methods: {
    changeSort(val) {
      this.sortBy = val
      this.visibleSort = false
    },
  },
  computed: {
    ...mapGetters(['getRegions']),
    filterColor() {
      return this.visibleFilter ? 'red' : 'black'
    },
    sortColor() {
      return this.visibleSort ? 'red' : 'black'
    },
  }
}
</script>

<style lang="scss" scoped>
.event-participants {
  background: #F5F5F5;
  padding: 150px 50px 50px 50px;
  display: flex;
  min-height: 100vh;
  gap: 86px;

  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 104px 20px 50px 20px;
    gap: 20px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;

    &-cards {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &-search {
      display: flex;
      align-items: center;
      gap: 30px;
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid #fff;

      @media (max-width: 992px) {
        gap: 20px;
        margin-bottom: 20px;
      }

      @media (max-width: 768px) {
        padding: 0;
        border: none;
      }

      &-icons {
        display: flex;
        align-items: center;
        gap: 20px;

        @media (max-width: 992px) {
          gap: 10px;
        }

        img {
          cursor: pointer;
        }
      }
    }
  }
}
::v-deep .filter__row {
  display: grid !important;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 992px) {
    display: flex !important;
    flex-direction: column;
  }
}
</style>
