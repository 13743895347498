<template>
  <div class="nav-bar">
    <router-link tag="h1" :to='`/event-participants/${$route.params.id}`' class="nav-bar__title">{{ $t('event-participants') }}</router-link>
    <div class="nav-bar__links">
      <router-link
          v-for="(link, index) in getLinks"
          :to="link.path"
          :key="index"
          class="nav-bar__links-link"
      >
        {{ link.text }}
      </router-link>
    </div>
    <ui-menu v-model="visibleSettings">
      <template #activator>
        <img src="@/assets/svg/icon-settings-black.svg" alt="">
      </template>
      <template #body>
        <div class="sort">
          <router-link
              v-for="(link, index) in getLinks"
              :to="link.path"
              :key="index"
              class="nav-bar__links-link"
              @click.native="visibleSettings = false"
          >
            {{ link.text }}
          </router-link>
        </div>
      </template>
    </ui-menu>
    <p class="nav-bar__delete" v-if="$store.state.eventsStore.deletedEventUsers.length">{{ $t('cancel-registration') }}</p>
  </div>
</template>

<script>
import UiMenu from "@/components/ui/UiMenu.vue";
import {mapGetters} from "vuex";

export default {
  name: "EventParticipantsNavBar",
  components: {UiMenu},
  data() {
    return {
      // links: [
      //   { id: 0, text: 'Изменить мероприятие', path: `/edit-event-management/${this.getEventsId?.id}` },
      //   { id: 1, text: 'Зарегистрировать участника', path: `/event-participants/register/${this.getEventsId?.id}` },
      // ],
      visibleSettings: false
    }
  },

  computed: {
    ...mapGetters(['getEventsId']),

    getLinks () {
      return [
        { id: 0, text: this.$t('change-event'), path: `/edit-event-management/${this.$route.params.id}` },
        { id: 1, text: this.$t('register-a-participant'), path: `/event-participants/register/${this.$route.params.id}` },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-bar {
  position: relative;

  @media (max-width: 1200px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
    z-index: 11;
  }

  img {
    @media (min-width: 1200px) {
      display: none;
    }
  }

  &__title {
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #343432;
    white-space: nowrap;
    cursor: pointer;

    @media (max-width: 1200px) {
      margin-bottom: 0;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (max-width: 1200px) {
      display: none;
    }

    .router-link-active {
      color: red;
    }

    &-link {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #343432;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  &__delete {
    position: absolute;
    bottom: 0;
    color: #9A9A9A;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
